// @flow

import * as React from 'react';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import classnames from 'classnames';

import Links from '../../plugins/i18n/components/Link';

const styles = (theme: Theme) => ({
  root: {
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing.unit * 1.5,
      paddingRight: theme.spacing.unit * 1.5,
    },
  },
});

type Props = {
  label: React.Node,
  href?: string,
  to?: string,
  className?: string,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const AppBarLink = ({ href, to, label, className, classes, ...props }: Props) => (
  <Button
    color="primary"
    className={classnames(className, classes.root)}
    {...props}
    {...(!!to
      ? {
          component: Links,
          to,
        }
      : { href })}
  >
    {label}
  </Button>
);

AppBarLink.defaultProps = {
  href: undefined,
  to: undefined,
  className: undefined,
};

export default withStyles(styles)(AppBarLink);
