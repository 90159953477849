/* eslint-disable implicit-arrow-linebreak */
import { injectIntl } from 'react-intl';
import compose from 'recompose/compose';
import withPropsOnChange from 'recompose/withPropsOnChange';

import navigationLinks from '../config/navigationLinks';

export default () =>
  compose(
    injectIntl,
    withPropsOnChange(
      (props, nextProps) => props.intl.locale !== nextProps.intl.locale,
      ({ intl }) => ({
        links: navigationLinks.map(link => ({
          ...link,
          label: intl.formatMessage({
            id: `navigation.link.${link.key}`,
            defaultMessage: link.label,
          }),
        })),
      }),
    ),
  );
