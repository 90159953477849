// @flow

import * as React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import classnames from 'classnames';

import AppBarLogo from './AppBarLogo';
import type { Props as AppBarLogoProps } from './AppBarLogo';
import { logoClasses } from './styles';

const styles = (theme: Theme) => ({
  root: {
    position: 'relative',
    alignItems: 'center',
  },
  wrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftIcon: {},
  rightIcon: {},
  ...logoClasses(theme),
});

type Props = {
  title: string,
  toggleLeftDrawer: Function,
  LogoProps?: AppBarLogoProps,
  className: string,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

class AppBarPhone extends React.PureComponent<Props> {
  static defaultProps = {
    LogoProps: {},
  };

  handleIconClick = () => {
    const { toggleLeftDrawer } = this.props;
    toggleLeftDrawer();
  };

  render() {
    // eslint-disable-next-line object-curly-newline
    const { className, classes, title, LogoProps } = this.props;
    return (
      <Toolbar className={classnames(className, classes.root)}>
        <div className={classes.wrapper}>
          <AppBarLogo
            title={title}
            {...LogoProps}
            classes={{ root: classes.logoRoot }}
          />
          <div className={classes.rightIcon} />
        </div>
      </Toolbar>
    );
  }
}

export default withStyles(styles)(AppBarPhone);
