// @flow
import * as React from 'react';
import withStyles, {
  type CSSProperties,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import MuiSnackbar from '@material-ui/core/Snackbar';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import omit from 'lodash/fp/omit';

import RichText from '../../plugins/prismic/components/RichText';
import type { SnackbarProps } from '../../plugins/material-ui/modules/snackbar/types';
import * as variants from '../../plugins/material-ui/modules/snackbar/config/variants';

import defaultIcons from './defaultIcons';

const styles = (theme: Theme) => ({
  root: {},
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    '& p, & ul, & ol': {
      margin: `0.5em 0`,
    },
    '& a': {
      color: 'inherit',
    },
  },
  ...({
    [variants.default]: {
      backgroundColor: undefined,
    },
    [variants.success]: {
      backgroundColor: green[600],
    },
    [variants.info]: {
      backgroundColor: blue[400],
    },
    [variants.error]: {
      backgroundColor: theme.palette.error.dark,
    },
    [variants.warning]: {
      backgroundColor: amber[700],
    },
  }: $ObjMap<typeof variants, () => CSSProperties>),
});

const omitOwnClasses = omit(['icon', 'message', ...Object.keys(variants)]);

export type TransitionCallback = (element: HTMLElement) => void;

export type ClassKey =
  | $Keys<$Call<typeof styles, Theme>>
  | $Keys<typeof variants>;

export type Props = $ReadOnly<{
  ...$Exact<SnackbarProps>,
  classes: { [ClassKey]: string },
}>;

const Snackbar = ({
  message,
  classes,
  variant = variants.default,
  Icon,
  ...props
}: Props): React.Node => {
  const IconComponent =
    Icon === null ? null : Icon || defaultIcons[variant] || null;

  return (
    <MuiSnackbar
      {...props}
      classes={React.useMemo(() => omitOwnClasses(classes), [classes])}
      ContentProps={{
        className: classes[variant],
        'aria-describedby': 'snackbar-message',
      }}
      message={
        <div id="snackbar-message" className={classes.message}>
          {IconComponent ? <IconComponent className={classes.icon} /> : null}
          {typeof message === 'string' ? <RichText html={message} /> : message}
        </div>
      }
    />
  );
};

Snackbar.defaultProps = {
  variant: variants.default,
  className: undefined,
  onClose: undefined,
};

export default withStyles(styles)(Snackbar);
