// @flow

import map from 'lodash/map';
import filter from 'lodash/filter';
import * as React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import classnames from 'classnames';

import AppBarLink from './AppBarLink';
import AppBarLogo from './AppBarLogo';
import type { Props as AppBarLogoProps } from './AppBarLogo';
import { logoClasses } from './styles';
import type { LinkProps } from './types';

const styles = (theme: Theme) => ({
  root: {
    position: 'relative',
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  links: {
    display: 'flex',
  },
  link: {
    pointerEvents: 'all',
    color: 'inherit',
  },
  linkButton: {
    color: 'inherit',
  },
  ...logoClasses(theme),
});

type Props = {
  links: Array<LinkProps<Object>>,
  title: string,
  toggleLeftDrawer: Function,
  LogoProps: AppBarLogoProps,
  className: string,
  classes?: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

class AppBar extends React.PureComponent<Props> {
  static defaultProps = {
    classes: {},
  };

  renderLinks = position => {
    const { links, classes } = this.props;
    return (
      <div className={classes?.links}>
        {map(filter(links, { position }), this.renderLink)}
      </div>
    );
  };

  renderLink = ({
    Component = AppBarLink,
    ...link
  }: LinkProps<Object>): React.Element<any> => {
    const { classes } = this.props;
    return (
      <Component
        key={link.key}
        {...link}
        className={classnames(classes?.link, classes?.linkButton)}
      />
    );
  };

  render() {
    const { title, LogoProps, className, classes } = this.props;
    return (
      <Toolbar className={classnames(className, classes?.root)}>
        <div className={classes?.wrapper}>
          {this.renderLinks('left')}
          <AppBarLogo
            title={title}
            {...LogoProps}
            classes={{ root: classes?.logoRoot }}
          />
          {this.renderLinks('right')}
        </div>
      </Toolbar>
    );
  }
}

export default withStyles(styles)(AppBar);
