/* eslint-disable jsx-a11y/no-noninteractive-tabindex,jsx-a11y/no-static-element-interactions */
// @flow

import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import classnames from 'classnames';

const styles = (unusedTheme: Theme) => ({
  root: {},
  logo: {},
  logoButton: {
    cursor: 'pointer',
    pointerEvents: 'all',
    outline: 'none',
  },
  image: {},
})

export type Props = {
  logo?: ?React.Node,
  logoSrc?: string,
  title: string,
  onClick?: Function,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  className?: string,
};

class AppBarLogo extends React.PureComponent<Props> {
  static defaultProps = {
    logo: null,
    logoSrc: undefined,
    onClick: undefined,
    className: '',
  };

  render() {
    const { className, logo, logoSrc, onClick, title, classes } = this.props;

    if (!logo && !logoSrc) {
      return null
    }

    return (
      <div className={classnames(className, classes.root)}>
        <div
          className={classnames(classes.logo, {
            [classes.logoButton]: !!onClick,
          })}
          onClick={onClick}
          onKeyPress={onClick}
          role={onClick ? 'button' : undefined}
          tabIndex={onClick ? 0 : undefined}
        >
          {logo || <img src={logoSrc} alt={title} className={classes.image} />}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AppBarLogo);