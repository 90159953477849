// @flow
import * as React from 'react';
import Link from '@material-ui/core/Link';

export type Props = {
  phone: string,
  children?: React.Node,
};

const PhoneLink = ({ phone, children, ...props }: Props) => (
  <Link {...props} href={`tel:${phone}`}>
    {children || phone}
  </Link>
);

PhoneLink.defaultProps = {
  children: undefined,
};

export default PhoneLink;
