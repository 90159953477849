// @flow
import compose from 'recompose/compose';
import withPropsOnChange from 'recompose/withPropsOnChange';
import mapProps from 'recompose/mapProps';
import type { HOC } from 'recompose';
import omit from 'lodash/fp/omit';

import i18n from '../i18n';
import type { Locale } from '../types';
import withPageContext from '../../page-context/hocs/withPageContext';

export type PageTranslations = Array<{|
  path: string,
  current: boolean,
  exist: boolean,
  locale: Locale,
|}>;

export type WithPageTranslationsHOC<E: {}> = HOC<
  { ...$Exact<E>, pageTranslations: PageTranslations },
  E,
>;

export default function withPageTransalations<
  Enhanced: {},
>(): WithPageTranslationsHOC<Enhanced> {
  return compose(
    withPageContext(),
    withPropsOnChange(['pageContext'], ({ pageContext }) => ({
      pageTranslations: i18n.getLocales().map(locale => ({
        current: locale.id === pageContext.localeId,
        exist: !!pageContext.translations[locale.id],
        locale,
        path: pageContext.translations[locale.id] || '',
      })),
    })),
    mapProps(omit(['pageContext'])),
  );
}
