// @flow
import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import classnames from 'classnames';
import Phone from '@material-ui/icons/Call';
import { injectIntl, FormattedMessage } from 'react-intl';

import type { Scalars } from '../schema.flow';

import PageWrapper from './PageWrapper';
import LanguagePicker from './i18n/LanguagePicker';
import useSiteInfo from '../hooks/useSiteInfo';
import AppBarLogo from './AppBar/AppBarLogo';
import Copyright from './Copyright';
import PhoneLink from './PhoneLink';

const styles = (theme: Theme) => ({
  root: {
    background: theme.palette.darkGrey.main,
    position: 'relative',
  },
  logoRoot: {},
  container: {
    display: 'flex',
    flex: 0,
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
    },
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingBottom: theme.spacing.unit * 4,
    },
  },
  contact: {
    display: 'inherit',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: theme.spacing.unit * 4,
      paddingBottom: theme.spacing.unit * 4,
      borderTop: '1px solid #4e4e4e',
      borderBottom: '1px solid #4e4e4e',
    },
  },
  phone: {
    display: 'inherit',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: theme.spacing.unit * 4,
    [theme.breakpoints.up('md')]: {
      paddingBottom: 0,
      paddingRight: theme.spacing.unit * 4,
    },
  },
  address: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  icon: {
    fontSize: '40px',
    margin: theme.spacing.unit,
  },
  color: {
    color: 'white',
  },
  copyrightInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 4,
    borderTop: '1px solid #4e4e4e',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px',
      borderTop: 'none',
    },
  },
  languagePicker: {
    paddingBottom: theme.spacing.unit * 4,
  },
  linkButton: {
    color: theme.palette.common.white,
  },
  copyLinks: {
    color: theme.palette.common.white,
    '& a,visited': {
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
    '& a:hover': {
      color: theme.palette.secondary.main,
    },
  },
});

export type Props = {
  title: $ElementType<Scalars, 'String'>,
  LogoProps: $Diff<React.ElementConfig<typeof AppBarLogo>, { title: any }>,
  className?: string,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const Footer = ({ title, LogoProps, className, classes }: Props) => {
  const siteInfo = useSiteInfo();
  return (
    <div className={classnames(className, classes.root)}>
      <PageWrapper guttersH limitWidth>
        <div className={classes.container}>
          <div className={classes.logo}>
            <AppBarLogo
              title={title}
              {...LogoProps}
              classes={{ root: classes.logoRoot }}
            />
          </div>
          <div className={classes.contact}>
            <div className={classes.phone}>
              <Phone className={classes.icon} color="primary" />
              <Typography variant="h6" align="left" className={classes.color}>
                <FormattedMessage id="contact.callUs" />
                {siteInfo?.data?.phone?.text ? (
                  <>
                    <br />
                    <PhoneLink
                      color="inherit"
                      phone={siteInfo?.data?.phone?.text || ''}
                    />
                  </>
                ) : null}
                {siteInfo?.data?.phone_mobile?.text ? (
                  <>
                    <br />
                    <PhoneLink
                      color="inherit"
                      phone={siteInfo?.data?.phone_mobile?.text || ''}
                    />
                  </>
                ) : null}
              </Typography>
            </div>
            <div className={classes.address}>
              <Typography variant="body1" className={classes.color}>
                {siteInfo?.data?.address_line_1?.text}
              </Typography>
              <Typography variant="body1" className={classes.color}>
                {siteInfo?.data?.address_line_2?.text}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.copyrightInfo}>
          <div className={classes.languagePicker}>
            <LanguagePicker classes={{ linkButton: classes.linkButton }} />
          </div>
          <div>
            <Copyright className={classes.copyLinks} />
          </div>
        </div>
      </PageWrapper>
    </div>
  );
};

Footer.defaultProps = {
  className: undefined,
};

export default injectIntl(withStyles(styles)(Footer));
