// @flow
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import MUICookieConsent from 'material-ui-cookie-consent';
import RichText from '../plugins/prismic/components/RichText';
import Link from '../plugins/i18n/components/Link';
import usePrismicCookies from '../hooks/usePrismicCookies';

const CookieConsent = () => {
  const cookies = usePrismicCookies();
  const data = cookies && cookies.data;
  return (
    <MUICookieConsent
      cookieName="CIOS-cookie-consent"
      snackbarAnchor={{ horizontal: 'right', vertical: 'bottom' }}
      message={
        RichText.hasValue(data?.description) ? (
          <Typography color="inherit" variant="body1">
            <RichText {...data?.description} />
          </Typography>
        ) : null
      }
      actions={
        data?.read_more_button?.text ? (
          <Button component={Link} to="/cookies">
            <Typography
              color="default"
              variant="button"
              style={{ letterSpacing: 0.01 }}
            >
              {data?.read_more_button?.text}
            </Typography>
          </Button>
        ) : null
      }
      acceptButtonLabel={
        data?.accept_button?.text ? (
          <Typography color="default" variant="button">
            {data?.accept_button?.text}
          </Typography>
        ) : null}
    />
  );
};

export default CookieConsent;
