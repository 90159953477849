// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography/Typography';
import { FormattedMessage } from 'react-intl';

import Link from '../plugins/i18n/components/Link';

export type Props = {
  className: string,
};

const Copyright = ({ className }: Props) => {
  return (
    <Typography variant="caption" className={className}>
      <FormattedMessage
        id="copyright"
        defaultMessage="Origen Studio {year}"
        values={{ year: new Date().getUTCFullYear() }}
      />
      <span> | </span>
      <Link to="/legal">
        <FormattedMessage id="copyright.legal" defaultMessage="Privacy" />
      </Link>
      <span> | </span>
      <Link to="/privacy">
        <FormattedMessage id="copyright.privacy" defaultMessage="Privacy" />
      </Link>
      <span> | </span>
      <Link to="/cookies">
        <FormattedMessage id="copyright.cookies" defaultMessage="Cookies" />
      </Link>
    </Typography>
  );
};

export default Copyright;
