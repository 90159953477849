// @flow
import * as React from 'react';

import AppointmentDialogContext from '../config/AppointmentDialog/context';

export type Props = {|
  children: React.Node,
|};

const AppointmentDialogProvider = (props: Props) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const open = React.useCallback(() => setOpen(true));
  const close = React.useCallback(() => setOpen(false));

  return (
    <AppointmentDialogContext.Provider
      value={React.useMemo(() => ({ isOpen, open, close }), [isOpen])}
      {...props}
    />
  );
};

export default AppointmentDialogProvider;
