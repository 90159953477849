// @flow
import * as React from 'react';
import compose from 'recompose/compose';
import { StaticQuery, graphql } from 'gatsby';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import MaterialUILayout from 'material-ui-layout';
import classnames from 'classnames';

import type { Query } from '../schema.flow';
import FileImage from '../plugins/prismic/components/FileImage';
import AppBar from '../components/AppBar';
import Footer from '../components/Footer';
import withNavigationLinks from '../hocs/withNavigationLinks';
import withOnLogoClickHandler from '../hocs/withOnLogoClickHandler';
import withTransparentAppBarOnScroll from '../hocs/withTransparentAppBarOnScroll';

const styles = (theme: Theme) => ({
  appBar: {
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    color: theme.palette.text.primary,
    boxShadow: 'none',
    transition: 'all 400ms ease',
  },
  transparentAppBar: {
    opacity: 0,
    pointerEvents: 'none !important',
  },
  transparentableAppBar$mainFixedAppBar: {
    marginTop: 0,
  },
  main: {
    backgroundColor: 'white',
    height: 'initial !important',
  },
  footer: {
    padding: 0,
  },
});

export type QueryResults = {
  ...$Exact<Query>,
  logo: $ElementType<Query, 'file'>,
  logo2: $ElementType<Query, 'file'>,
};

export type Props = {
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  onLogoClick: Event => void,
  transparentableAppBar?: boolean,
  transparentAppBar?: boolean,
  links: $ElementType<React.ElementConfig<typeof AppBar>, 'links'>,
};

class PageLayout extends React.PureComponent<Props> {
  static defaultProps = {
    transparentableAppBar: false,
    transparentAppBar: false,
  };

  renderResolvedQuery = (data: QueryResults) => {
    const {
      classes: {
        appBar: classes$appBar,
        transparentAppBar: classes$transparentAppBar,
        transparentableAppBar$mainFixedAppBar: classes$transparentableAppBar$mainFixedAppBar,
        ...classes
      },
      onLogoClick,
      links,
      transparentableAppBar,
      transparentAppBar,
    } = this.props;

    return (
      <MaterialUILayout
        {...this.props}
        classes={{
          ...classes,
          mainFixedAppBar: transparentableAppBar
            ? classes$transparentableAppBar$mainFixedAppBar
            : undefined,
        }}
        mainGrow={false}
        stickyFooter
        appBarContent={
          <AppBar
            title={data?.site?.siteMetadata?.title}
            LogoProps={{
              logo: (
                <FileImage
                  file={data?.logo}
                  alt={data?.site?.siteMetadata?.title || 'Logo'}
                />
              ),
              onClick: onLogoClick,
            }}
            links={links}
          />
        }
        appBarProps={{
          className: classnames(classes$appBar, {
            [`${classes$transparentAppBar}`]: transparentAppBar,
          }),
        }}
        footerContent={
          <Footer
            title={data?.site?.siteMetadata?.title || ''}
            LogoProps={{
              logo: (
                <FileImage
                  file={data?.logo2}
                  alt={data?.site?.siteMetadata?.title || 'Logo'}
                />
              ),
              onClick: onLogoClick,
            }}
          />
        }
      />
    );
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query PageLayoutQuery {
            site {
              siteMetadata {
                title
              }
            }
            logo: file(relativePath: { eq: "CIOS-BigLogo.png" }) {
              childImageSharp {
                fixed(width: 200) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
            logo2: file(relativePath: { eq: "CIOS-BigLogoWhite.png" }) {
              childImageSharp {
                fixed(width: 250) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
        `}
        render={this.renderResolvedQuery}
      />
    );
  }
}

export default compose(
  withOnLogoClickHandler(),
  withTransparentAppBarOnScroll(),
  withNavigationLinks(),
  withStyles(styles),
)(PageLayout);
