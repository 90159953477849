// @flow
import { navigate } from 'gatsby';

import type { Locale } from '../types';
import * as i18n from '../i18n';

export const getLocalizedPath = (currentLocale: Locale | string, path: string) =>
  i18n.localizePath(currentLocale, path);

export const localizedNavigate = (
  currentLocale: Locale | string,
  to: string,
  options?: {},
) => navigate(getLocalizedPath(currentLocale, to), options);
