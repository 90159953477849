// @flow

import * as React from 'react';
import classnames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';

export const curryLimitWidthClasses = (name: string = 'limitWidth') => (
  theme: Theme,
) => ({
  [name]: {
    maxWidth: theme.breakpoints.values.xl,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.breakpoints.values.sm,
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.breakpoints.values.md,
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '90vw',
      width: '100%',
    },
  },
});

export const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flex: '1',
    position: 'relative',
  },
  wrapper: {},
  limitWidth: {},
  ...curryLimitWidthClasses()(theme),
  page: {
    width: '100%',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  guttersH: theme.mixins.gutters(),
  guttersV: {
    paddingTop: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 4,
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing.unit * 6,
      paddingBottom: theme.spacing.unit * 6,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing.unit * 8,
      paddingBottom: theme.spacing.unit * 8,
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing.unit * 10,
      paddingBottom: theme.spacing.unit * 10,
    },
  },
});

export type Props = {
  limitWidth?: boolean,
  guttersH?: boolean,
  guttersV?: boolean,
  children: React.Node,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const PageWrapper = ({
  classes,
  limitWidth,
  guttersH,
  guttersV,
  children,
}: Props): React.Node => (
  <div
    className={classnames(classes.page, classes.root, {
      [classes.justifyContentCenter]: limitWidth,
    })}
  >
    <div
      className={classnames(classes.wrapper, {
        [classes.page]: !limitWidth,
        [classes.limitWidth]: limitWidth,
        [classes.guttersH]: guttersH,
        [classes.guttersV]: guttersV,
      })}
    >
      {children}
    </div>
  </div>
);

PageWrapper.defaultProps = {
  limitWidth: false,
  guttersH: false,
  guttersV: false,
};

export default withStyles(styles)(PageWrapper);
