/* eslint-disable import/prefer-default-export */
// @flow

import type { Theme } from '@material-ui/core/styles';

export const logoClasses = (theme: Theme) => ({
  logoRoot: {
    position: 'absolute',
    pointerEvents: 'none',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
});
