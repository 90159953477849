/* eslint-disable implicit-arrow-linebreak */
import { injectIntl } from 'react-intl';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';

import { localizedNavigate } from '../plugins/i18n/helpers';

export default () =>
  compose(
    injectIntl,
    withHandlers({
      onLogoClick: ({ intl }) => () => localizedNavigate(intl.locale, '/'),
    }),
  );
