// @flow
import * as React from 'react';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import classnames from 'classnames';
import compose from 'recompose/compose';
import type { HOC } from 'recompose';

import Link from '../../plugins/i18n/components/Link';
import withPageTranslations, {
  type PageTranslations,
} from '../../plugins/i18n/hocs/withPageTranslations';

const styles = (theme: Theme) => ({
  root: {
    margin: 0,
    padding: 0,
    paddingLeft: '24px',
    display: 'flex',
  },
  listItem: {
    listStyle: 'none',
  },
  link: {},
  linkButton: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    minWidth: 'unset',
  },
  active: {
    color: `${theme.palette.primary.main} !important`,
  },
});

type Props = {
  pageTranslations: PageTranslations,
  className?: string,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const LanguagePicker = ({
  pageTranslations,
  classes,
  className,
  ...props
}: Props) => (
  <ul {...props} className={classnames(className, classes.root)}>
    {pageTranslations.map(({ path, locale, current, exist }) => {
      const disabled = !(current || exist);
      return (
        <li key={locale.id} className={classes.listItem}>
          {current || disabled ? (
            <Button
              disableRipple
              href="#"
              variant={current ? 'outlined' : undefined}
              color={current ? 'primary' : undefined}
              disabled={disabled}
              className={classnames(classes.linkButton, {
                [classes.active]: current,
              })}
            >
              {locale.id}
            </Button>
          ) : (
            <Link className={classes.link} to={path} localized>
              <Button disableRipple className={classes.linkButton}>
                {locale.id}
              </Button>
            </Link>
          )}
        </li>
      );
    })}
  </ul>
);

LanguagePicker.defaultProps = {
  className: undefined,
};

const enhancer: HOC<
  Props,
  {
    ...$Exact<$Diff<Props, { classes: any, pageTranslations: any }>>,
    classes?: $ElementType<Props, 'classes'>,
  },
> = compose(
  withPageTranslations(),
  withStyles(styles),
);

export default enhancer(LanguagePicker);
