// @flow
import { graphql, useStaticQuery } from 'gatsby';
import find from 'lodash/find';

import usePageContext from '../plugins/page-context/hooks/usePageContext';
import type { Query, PrismicCookiesPage } from '../schema.flow';

const query = graphql`
  query UsePrismicCookiesDefaultQuery {
    allPrismicCookiesPage {
      edges {
        node {
          id
          lang
          data {
            meta_title {
              text
            }
            meta_description {
              text
            }
            meta_keywords {
              meta_keyword {
                text
              }
            }
            title {
              text
            }
            description {
              text
              html
            }
            read_more_button {
              text
            }
            accept_button {
              text
            }
            page_title {
              text
            }
            page_description {
              text
              html
            }
          }
        }
      }
    }
  }
`;

export default function usePrismicCookies(): ?PrismicCookiesPage {
  const { locale } = usePageContext();
  const data = useStaticQuery<Query>(query);
  return find(
    data.allPrismicCookiesPage?.edges,
    ({ node }) => node?.lang === locale.prismicLocale,
  )?.node;
}
