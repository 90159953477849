// @flow
import * as React from 'react';
import noop from 'lodash/noop';

export type AppointmentForm$Context = {
  isOpen: boolean,
  open: () => void,
  close: () => void,
};

export default React.createContext<AppointmentForm$Context>({
  isOpen: false,
  open: noop,
  close: noop,
});
