// @flow
import type { LinkProps } from '../components/AppBar/types';
import AppointmentDialogButton from '../components/AppointmentDialogButton';

export default ([
  {
    key: 'appointment',
    Component: AppointmentDialogButton,
    label: 'Pide cita',
    variant: 'contained',
    color: 'secondary',
    position: 'right',
  },
]: Array<LinkProps<*>>);
