import * as React from 'react';
import OnScroll from 'react-on-scroll';

export default function withTransparentAppBarOnScroll() {
  return Component => {
    const WithTransparentAppBarOnScroll = ({
      transparentAppBarHeightFactor,
      ...props
    }) => {
      const [scrollTriggered, setScrollTriggered] = React.useState(
        Number.isFinite(transparentAppBarHeightFactor),
      );
      const onScrollTrigger = React.useCallback(visible => {
        setScrollTriggered(!!visible);
      }, []);

      if (Number.isFinite(transparentAppBarHeightFactor)) {
        return (
          <OnScroll
            triggerBase="top"
            triggers={[
              {
                top:
                  Number.isFinite(transparentAppBarHeightFactor) &&
                  global.window
                    ? -(
                        global.window.innerHeight *
                        transparentAppBarHeightFactor
                      )
                    : 0,
                callback: onScrollTrigger,
              },
            ]}
          >
            <Component
              {...props}
              transparentableAppBar
              transparentAppBar={scrollTriggered}
            />
          </OnScroll>
        );
      }
      return (
        <Component
          {...props}
          transparentableAppBar={false}
          transparentAppBar={false}
        />
      );
    };

    return WithTransparentAppBarOnScroll;
  };
}
