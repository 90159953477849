// @flow

import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import AppBar from './AppBar';
import AppBarPhone from './AppBarPhone';

export default compose(
  withWidth(),
  branch(
    ({ width }) => isWidthDown('xs', width),
    renderComponent(AppBarPhone),
  ),
)(AppBar);
