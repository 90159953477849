// @flow
import * as React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link as GatsbyLink } from 'gatsby';
import { type IntlShape } from 'react-intl';
import classnames from 'classnames';
import compose from 'recompose/compose';
import type { HOC } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';

import withLocalizePath, { type LocalizePath } from '../hocs/withLocalizePath';

export const styles = () => ({
  root: {
    textDecoration: 'none',
  },
});

export type Props = {
  ...$Exact<React.ElementConfig<typeof GatsbyLink>>,
  intl: IntlShape,
  localizePath: LocalizePath,
  localized?: boolean,
  className?: string,
  classes: { [$Keys<$Call<typeof styles>>]: string },
};

const Link = ({
  to,
  intl,
  localizePath,
  localized,
  classes,
  className,
  ...props
}: Props) => (
  <GatsbyLink
    {...props}
    className={classnames(className, classes.root)}
    to={localized ? to : localizePath(to)}
  />
);

Link.defaultProps = {
  className: undefined,
  localized: false,
};

const enhancer: HOC<Props, *> = compose(
  withLocalizePath(),
  withStyles(styles),
);

export default enhancer(Link);
