/* eslint-disable react/destructuring-assignment */
// @flow

import * as React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import AppIntlProvider from '../plugins/i18n/components/AppIntlProvider';
import AppHelmet from '../plugins/helmet/components/AppHelmet';
import PageContext, { type Page$Context } from '../plugins/page-context';
import SnackbarProvider from '../plugins/material-ui/modules/snackbar/components/SnackbarProvider';
import CookieConsent from '../components/CookieConsent';
import Snackbar from '../components/Snackbar';
import AppointmentDialogProvider from '../components/AppointmentDialogProvider';
import PageLayout from './PageLayout';

export type Props = {
  children: React.Node,
  pageContext: Page$Context,
};

const AppLayout = ({ children, ...props }: Props) => (
  <AppIntlProvider {...props}>
    <PageContext.Provider value={props.pageContext}>
      <SnackbarProvider Snackbar={Snackbar}>
        <AppointmentDialogProvider>
          <ParallaxProvider>
            <AppHelmet {...props} />
            <CookieConsent />
            <PageLayout
              transparentAppBarHeightFactor={
                props.pageContext.originalPath === '/' ? 1 / 3 : null
              }
            >
              {children}
            </PageLayout>
          </ParallaxProvider>
        </AppointmentDialogProvider>
      </SnackbarProvider>
    </PageContext.Provider>
  </AppIntlProvider>
);

export default AppLayout;
