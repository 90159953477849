// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';

import useAppointmentDialog from '../hooks/useAppointmentDialog';

const styles = () => ({
  root: {},
  containedSecondary: {
    color: 'white',
  },
});

export type Props = {|
  ...$Exact<$Diff<React.ElementConfig<typeof Button>, { children: any }>>,
  children?: React.Node,
  label?: React.Node,
  onClick?: Event => void,
|};

const AppointmentDialogButton = ({
  onClick,
  label,
  children,
  ...props
}: Props) => {
  const appointmentDialog = useAppointmentDialog();
  return (
    <Button
      {...props}
      onClick={React.useCallback(
        (event: Event) => {
          if (onClick) {
            onClick(event);
          }
          if (!event.defaultPrevented) {
            appointmentDialog.open();
          }
        },
        [onClick],
      )}
    >
      {label || children || (
        <FormattedMessage
          id="appointmentDialog.button"
          defaultMessage="Contact us"
        />
      )}
    </Button>
  );
};

AppointmentDialogButton.defaultProps = {
  onClick: undefined,
  label: undefined,
  children: undefined,
};

export default withStyles(styles)(AppointmentDialogButton);
