// @flow
import { graphql, useStaticQuery } from 'gatsby';
import find from 'lodash/find';

import usePageContext from '../plugins/page-context/hooks/usePageContext';
import type { Query, PrismicSiteInfo } from '../schema.flow';

const query = graphql`
  query SiteInfoQuery {
    allPrismicSiteInfo {
      edges {
        node {
          id
          lang
          data {
            email {
              text
            }
            phone {
              text
            }
            phone_mobile {
              text
            }
            google_maps {
              url
            }
            google_maps_embed {
              url
            }
            address_line_1 {
              text
            }
            address_line_2 {
              text
            }
            hours {
              text
              html
            }
          }
        }
      }
    }
  }
`;

export default function useSiteInfo(): ?PrismicSiteInfo {
  const { locale } = usePageContext();
  const data = useStaticQuery<Query>(query);
  return find(
    data.allPrismicSiteInfo?.edges,
    ({ node }) => node?.lang === locale.prismicLocale,
  )?.node;
}
