// @flow
import * as React from 'react';
import Image from 'gatsby-image';

import type { File } from '../../../schema.flow';

type Props = {
  ...$Exact<
    $Diff<
      React.ElementConfig<typeof Image>,
      {
        resolutions: any,
        sizes: any,
        fixed: any,
        fluid: any,
      },
    >,
  >,
  file: ?File,
};

const NodeImage = ({ file, ...props }: Props) =>
  file && file.childImageSharp ? (
    <Image
      {...props}
      // $FlowFixMe --> Reason: trust that the proper fragment has been used on the query
      resolutions={file.childImageSharp.resolutions}
      // $FlowFixMe --> Reason: trust that the proper fragment has been used on the query
      fixed={file.childImageSharp.fixed}
      // $FlowFixMe --> Reason: trust that the proper fragment has been used on the query
      fluid={file.childImageSharp.fluid}
      // $FlowFixMe --> Reason: trust that the proper fragment has been used on the query
      sizes={file.childImageSharp.sizes}
    />
  ) : null;

export default NodeImage;
